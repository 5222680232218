'use strict';
var CPRConfig = require('./config');
var CPRMain = require('./main');

var CPRGame = function(){
    var clock;
    var FLOOR_RES = 20;
    var FLOOR_YPOS = -300;

    var stage;
    var floorGeometry;

    var buildingModel;
    var buildings = [];
    var outerBuildings = [];
    var innerBuildings = [];
    var rows = 40;
    var initialSpeed = 2;
    var speed = {vl:initialSpeed,maxSpeed:20};
    var offset = 200; //distance away from the camera before disappearing building

    var player;
    var isPlayerLoaded = false;
    var movementSpeed = 6;
    var playerDirectionY;
    var playerDirectionX;

    function isEven(x) { return (x%2)==0; }
    function isOdd(x) { return !isEven(x); }
    function num(min, max) { return Math.random() * (max - min) + min; }

    function init(){
        clock = new THREE.Clock();

        // setup
        var initialYPos;
        var initialXPos;
        var playerZPos = -3.141592653589793;
        var camera = CPRMain.getCamera();
        var rotationZ;
        var positionZ;
        var joystickYPos;
        var joystickXPos;
        var initialCameraRotation = camera.rotation.z;
        var initialCameraPositionZ = camera.position.z;
        var initialCameraPositionY = camera.position.y;
        var initialPlayerZ;

        // lights
        var hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.8);
        CPRMain.getScene().add( hemisphereLight );
        hemisphereLight.position.y = 1000;


        // stage (contains the world)
        stage = new THREE.Object3D();
        CPRMain.getScene().add( stage );

        //make floor
        var floorGroup = new THREE.Object3D();
        var floorMaterial = new THREE.MeshLambertMaterial({
            color: 0x000000, //diffuse
            emissive: 0x000000,
            shading: THREE.FlatShading,
            side: THREE.DoubleSide,
        });
        floorGeometry = new THREE.PlaneGeometry( CPRConfig.FLOOR_DEPTH *2, CPRConfig.FLOOR_WIDTH, FLOOR_RES,FLOOR_RES );
        var floorMesh = new THREE.Mesh( floorGeometry, floorMaterial );
        floorGroup.add( floorMesh );
        stage.add( floorGroup );
        floorMesh.rotation.x = Math.PI/2;
        floorMesh.rotation.z = Math.PI/2;
        floorGroup.position.y = FLOOR_YPOS;
        stage.position.z = - CPRConfig.MOVE_STEP;
        floorGroup.position.z = 0;


        // make buildings
        var material = new THREE.MeshLambertMaterial( { color: 0x410649 } );
        /*var material;
        var path = '../assets/cyberpunkrun/';
        var mtlLoader = new THREE.MTLLoader();
        mtlLoader.setTexturePath(path+'textures');
        mtlLoader.setPath(path);
        mtlLoader.load('building.mtl', function(matl) {
            material = matl;
        });*/

        var loader = new THREE.OBJLoader();
        loader.load('../assets/cyberpunkrun/building.obj',function(object){
            //console.log('loaded')
            //object.material = material;
            object.children.forEach(function(child){
                child.material = material;
                child.geometry.computeFaceNormals();
                child.geometry.computeVertexNormals();
            });
            makeBuildings(object);
        })



        // player
        var playerMaterial = new THREE.MeshLambertMaterial( { color: 0xC41F9B } );
        loader.load('../assets/cyberpunkrun/car.obj',function(object){
            //console.log('loaded')
            //object.material = material;
            object.children.forEach(function(child){
                child.material = playerMaterial;
                child.geometry.computeFaceNormals();
                child.geometry.computeVertexNormals();
            });
            isPlayerLoaded = true;
            player = object;
            player.scale.set(20,20,20);
            player.position.x = 0;
            player.position.y = -50;
            player.rotateY(3.1);
            player.position.z = CPRConfig.MOVE_STEP - offset;
            stage.add(player);
            initialPlayerZ = player.rotation.z;

            // idle
            TweenMax.fromTo(player.position,4,{y:player.position.y},{y:player.position.y+10,ease:Strong.easeInOut,yoyo:true,repeat:-1});

            // animate camera in
            TweenMax.fromTo(camera.position,8,{z:initialCameraPositionZ-300,y:initialCameraPositionY+100},{z:initialCameraPositionZ,y:initialCameraPositionY,ease:Strong.easeOut});

            //console.log(player.rotation.z)
        })

        /*var playerGeom = new THREE.BoxGeometry(1,1,1);
        var playerMaterial = new THREE.MeshLambertMaterial( { color: 0xFFFFFF } );
        player = new THREE.Mesh(playerGeom,playerMaterial);
        player.scale.set(20,20,20);
        player.position.x = 0;
        player.position.y = -50;
        player.rotateY(3.1);
        player.position.z = CPRConfig.MOVE_STEP - offset;
        stage.add(player);
        initialPlayerZ = player.rotation.z;
        // idle
        TweenMax.fromTo(player.position,4,{y:player.position.y},{y:player.position.y+10,ease:Strong.easeInOut,yoyo:true,repeat:-1});
        // animate camera in
        TweenMax.fromTo(camera.position,8,{z:initialCameraPositionZ-300,y:initialCameraPositionY+100},{z:initialCameraPositionZ,y:initialCameraPositionY,ease:Strong.easeOut});
        stage.add(player);*/






        // joystick
        joystick.on('start end', function(evt, data) {
            //dump(evt.type);

            if (evt.type == "start") {
                initialYPos = data.position.y;
                initialXPos = data.position.x;
                // game speed
                TweenMax.to(speed,10,{vl:speed.maxSpeed,ease:Strong.easeOut});
                 // animate camera
                TweenMax.to(camera.rotation,2,{z:initialCameraRotation - 0.2,ease:Strong.easeInOut,onComplete:function(){
                    TweenMax.fromTo(camera.rotation,6,{z:camera.rotation.z},{z:initialCameraRotation + 0.2,ease:Strong.easeInOut,yoyo:true,repeat:-1});
                }})
                TweenMax.fromTo(camera.position,3,{z:camera.position.z},{z:initialCameraPositionZ - 70,ease:Strong.easeInOut});
                // swivel player while flying
                TweenMax.fromTo(player.rotation,4,{z:player.rotation.z},{z:player.rotation.z+0.2,ease:Strong.easeInOut,yoyo:true,repeat:-1});

                TweenMax.to(player.rotation,3,{z:initialPlayerZ - 0.1,ease:Strong.easeInOut,onComplete:function(){
                    TweenMax.fromTo(player.rotation,5,{z:player.rotation.z},{z:initialPlayerZ + 0.1,ease:Strong.easeInOut,yoyo:true,repeat:-1});
                }})

            }

            if (evt.type === "end") {
                playerDirectionY = null;
                playerDirectionX = null;
                // game speed
                TweenMax.to(speed,6,{vl:initialSpeed,ease:Strong.easeOut});
                initialYPos = 0;
                initialXPos = 0;
                // idle
                TweenMax.fromTo(player.position,4,{y:player.position.y},{y:player.position.y+10,ease:Strong.easeInOut,yoyo:true,repeat:-1})
                TweenMax.to(player.rotation,4,{z:initialPlayerZ,ease:Strong.easeInOut});
                 // animate camera
                TweenMax.to(camera.rotation,5,{z:initialCameraRotation,ease:Strong.easeInOut});
                TweenMax.to(camera.position,2,{z:initialCameraPositionZ,ease:Strong.easeOut});
            }
            //debug(data);
            //console.log(data)
        }).on('move', function(evt, data) {
            //debug(data);
            joystickYPos = initialYPos - data.position.y;
            joystickXPos = initialXPos - data.position.x;
            if (joystickYPos > 0 ) {
                playerDirectionY = 'up'
            } else {
                playerDirectionY = 'down'
            }

            if (joystickXPos < 0 ) {
                playerDirectionX = 'right'
            } else {
                playerDirectionX = 'left'
            }

            if (playerDirectionY === 'up') {
                TweenMax.to(player.position,movementSpeed/4,{y:joystickYPos*2,ease:Linear.easeNone});
            } else if (playerDirectionY === 'down') {
                TweenMax.to(player.position,movementSpeed/4,{y:joystickYPos*2,ease:Linear.easeNone});
            }

            if (playerDirectionX === 'right') {
                TweenMax.to(player.position,movementSpeed/4,{x:(-joystickXPos)-8,ease:Linear.easeNone});
            } else if (playerDirectionX === 'left') {
                TweenMax.to(player.position,movementSpeed/4,{x:(-joystickXPos)+8,ease:Linear.easeNone});
            }


        }).on('dir:up plain:up dir:left plain:left dir:down ' + 'plain:down dir:right plain:right', function(evt, data) {
                //dump(evt.type);

        }).on('pressure', function(evt, data) {

            //console.log(data)
        });
    }

    function makeBuildings(object){
        buildingModel = object;
        for (var i=0; i < rows; i++ ) {

            var buildingA = buildingModel.clone();
            var buildingB = buildingModel.clone();
            buildingA.scale.set(15,num(10,40),15);
            buildingB.scale.set(15,num(10,40),15);

            stage.add(buildingA);
            stage.add(buildingB);
            buildings.push(buildingA);
            buildings.push(buildingB);
            innerBuildings.push(buildingA);
            outerBuildings.push(buildingB);

            buildingA.position.y = FLOOR_YPOS;
            buildingB.position.y = FLOOR_YPOS;

            if (isOdd(i)) {
                buildingA.position.x = -160;
                buildingB.position.x = -460;
            } else {
                buildingA.position.x = 160;
                buildingB.position.x = 460;
            }
        }

        // separation in pairs on Z plane
        for (var i =0;i<buildings.length;i+=2){
            var subset = buildings.slice(i, i+2);
            for (var j = 0;j<subset.length;j++){
                subset[j].position.z = i*200;
            }
        }
    }


    function animate(){
        // move buildings
        for (var i=0;i < innerBuildings.length;i++){
            var singleBuilding = innerBuildings[i];
            if (singleBuilding.position.z > CPRConfig.MOVE_STEP + offset) {
                singleBuilding.position.z -= CPRConfig.MOVE_STEP;
                // shuffle height
                singleBuilding.scale.set(15,num(10,40),15);
            }
            singleBuilding.position.z += speed.vl;

        }

        for (var i=0;i < outerBuildings.length;i++){
            var singleBuilding = outerBuildings[i];
            if (singleBuilding.position.z > CPRConfig.MOVE_STEP + offset) {
                singleBuilding.position.z -= CPRConfig.MOVE_STEP;
                // shuffle height outer buildings, taller than inner
                singleBuilding.scale.set(15,num(30,70),15);
            }
            singleBuilding.position.z += speed.vl;

        }


    }




    return {
        init:init,
        animate:animate
    }

}();

module.exports = CPRGame;































/*'use strict';

var CPRGame = function(){
    var clock;


    function init(){
        clock = new THREE.Clock();

    }

    function animate(){
        var delta = clock.getDelta();


    }

    return {
        init:init,
        animate:animate
    }

}();

module.exports = CPRGame;
*/
