'use strict';
//- CyberPunk Run! by PWNK Digital

// GLOBAL CONFIG
var CPRConfig = require('./config');

// joystick
window.joystick = nipplejs.create({
    zone: document.getElementById('cyberpunkRun'),
    color: '#000'
});

// main.js: handles
var CPRMain = function(){
    // scene
    var camera, scene, renderer;
    var bgColor = 0x560968;

    // gameplay
    var score = 0;
    var isFirstGame = false;
    var controls;


    function init(){

        // setup scene
        camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 10000 );
        //camera.position.z = (CPRConfig.FLOOR_DEPTH/2) - (CPRConfig.FLOOR_DEPTH/4);
        camera.position.z = 0;
        camera.position.y = 0;
        camera.position.x = 0;

       /* // animate camera
        TweenMax.fromTo(camera.rotation,6,{z:camera.rotation.z - 0.1},{z:camera.rotation.z + 0.1,ease:Strong.easeInOut,yoyo:true,repeat:-1});
        TweenMax.fromTo(camera.position,5,{z:camera.position.z - 50},{z:camera.position.z + 10,ease:Strong.easeInOut,yoyo:true,repeat:-1});*/

        // dev
        //controls = new OrbitControls(camera);

        scene = new THREE.Scene();
        scene.fog = new THREE.Fog( bgColor, 0.001, 2600);

        renderer = new THREE.WebGLRenderer();
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.setClearColor( bgColor, 1 );
        $("#cyberpunkRun").append( renderer.domElement );



        // init game
        CPRGame.init();
        resize();
        animate();
    }

    // resize
    $(window).on('resize',function(){
        resize();
    });
    function resize(){
        var w = window.innerWidth;
        var h = window.innerHeight;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
    }

    // animate
    function animate(){
        requestAnimationFrame(animate);
        CPRGame.animate();
        renderer.render(scene, camera);

        // dev
        //controls.update();
    }

    return {
        init:init,
        getScene:function (){return scene;},
        getCamera:function (){return camera;},
    }
}();

$(document).ready(function(){
    CPRMain.init();
})

module.exports = CPRMain;


// GAME MODULES
var CPRGame = require('./game');




















/*let CyberpunkRun = function($target) {
    let self = this;
    self.$el = $target;


    // stage setup
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer();
    scene.fog = new THREE.FogExp2( 0xaaccff, 0.0007 );
    renderer.setSize( window.innerWidth, window.innerHeight );
    self.$el.get(0).appendChild( renderer.domElement );


    //

    // game loop
    var render = function () {




        renderer.render(scene, camera);
        requestAnimationFrame(render);
    };
    render();




    //resize
    $(window).on('resize',function(){
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
    })

};

module.exports = CyberpunkRun;
*/
