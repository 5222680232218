// GLOBAL CONFIG
var CPRConfig = function(){

    return {
        // dimensions
        FLOOR_WIDTH: 5000,  // size of floor in x direction
        FLOOR_DEPTH: 8000,  //size of floor in z direction
        MOVE_STEP: 7600      //z distance to move before recreating a new floor strip
    }

}();

module.exports = CPRConfig;
