'use strict';

let About = function($target) {
    let self = this;
    self.$el = $target;
    self.$bg = self.$el.find('.bg');
    self.$contentHolder = self.$el.find('.contentHolder');
    self.$logo = self.$el.find('.logoHolder');



    // toggle listeners
    $(window).on('about-open',function(){
        self.openPanel();
    })

    $(window).on('about-close',function(){
        self.closePanel();
    })

    // set contentholder
    TweenMax.set(self.$contentHolder,{y:-100+'%'});
    TweenMax.set(self.$el,{y:-100+'%'});

};

About.prototype.openPanel = function(){
    let self = this;
    self.$el.addClass('active');
    self.$contentHolder.scrollTop(0);
    TweenMax.set(self.$el,{y:0+'%'});
    TweenMax.to(self.$bg,0.55,{className:"+=active",ease:Strong.easeOut,overwrite:true,force3D:true});
    TweenMax.to(self.$contentHolder,0.55,{className:"+=active",ease:Strong.easeOut,overwrite:true,force3D:true});
    TweenMax.fromTo(self.$contentHolder,1,{y:100+'%'},{y:0+'%',ease:Strong.easeInOut,overwrite:false,force3D:true,delay:0.1,onComplete:function(){
            self.$contentHolder.addClass('scrollable');
    }})
    TweenMax.fromTo(self.$logo,1.5,{alpha:0,scale:2,rotation:-20},{alpha:1,scale:1,rotation:0,ease:Strong.easeOut,overwrite:true,force3D:true});
};

About.prototype.closePanel = function(){
    let self = this;
    self.$contentHolder.removeClass('scrollable');
    TweenMax.to(self.$contentHolder,0.55,{className:"-=active",ease:Strong.easeOut,overwrite:true,force3D:true});
    TweenMax.to(self.$bg,0.55,{className:"-=active",ease:Strong.easeOut,overwrite:true,force3D:true,delay:0.25,onComplete:function(){
        TweenMax.set(self.$el,{y:-100+'%'});
        self.$el.removeClass('active');
    }});
    TweenMax.fromTo(self.$logo,0.5,{alpha:1},{alpha:0,ease:Strong.easeOut,overwrite:true,force3D:true});
};

module.exports = About;
