'use strict';

let UI = function($target) {
    let self = this;
    self.$el = $target;
    self.$fullscreenButton = self.$el.find('.fullscreen');
    self.$contactButton = self.$el.find('.contact');
    self.$contactPanel = $('#contactPanel');
    self.$contactContent = self.$contactPanel.find('.content');
    self.$contactOpenUItargets = self.$el.find('.sound, .contact, .fullscreen');
    //self.$aboutOpenUItargets = self.$el.find('.sound, .contact, .fullscreen, .about, .closeAbout');
    self.$closePanelButton = $('.closePanelButton');
    self.$UIButtons = $('.hotspot');
    self.isFullscreen = false;
    self.isContactOpen = false;
    self.$aboutButton = self.$el.find('.about');
    self.$aboutOpenAnimate = self.$el.find('.about, .closeAbout');
    self.isAboutOpen = false;

    // hover states
    self.$UIButtons.on('mouseover',function(){
        if (!isMobile.any) {
            $(this).addClass('hover');
        }
    }).on('mouseleave',function(){
        if (!isMobile.any) {
            $(this).removeClass('hover');
        }
    }).on('click',function(){
        $(this).removeClass('hover');
    });

    // fullscreen button
    self.$fullscreenButton.on('click',function(){
        self.toggleFullScreen()
    });
    $(document).keyup(function(e) {
         if (e.keyCode == 27) { // escape key maps to keycode `27`
            if (isFullscreen){
                self.toggleFullScreen()
            }
        }
    });

    // hide fullscreen button on iOS
    if(isMobile.apple.device){
        self.$fullscreenButton.hide();
    };

    // contact panel
    self.$contactButton.on('click',function(){
        if(!self.isContactOpen){
            self.openContactPanel();
        } else {
            self.closeContactPanel();
        }
        self.$contactButton.removeClass('hover');
    });
    self.$closePanelButton.on('click',function(){
        self.closeContactPanel();
    });

    // close panel if click outside
    $(document).click(function(event) {
        if(!$(event.target).closest(self.$contactPanel).length &&
           !$(event.target).is(self.$contactPanel)) {
            if (self.isContactOpen) {
                self.closeContactPanel();
            }
        }
    });

    // about button
    self.$aboutOpenAnimate.on('click',function(){
        if(!self.isAboutOpen){
            $(window).trigger('about-open');
            self.isAboutOpen = true;
            TweenMax.to(self.$aboutOpenAnimate,0.55,{className:"+=active",ease:Strong.easeInOut,overwrite:true,force3D:true,onStart:function(){
                    //TweenMax.to(self.$contactOpenUItargets,0.55,{className:"+=aboutOpen",ease:Strong.easeInOut,overwrite:false,force3D:true});
            }});
        } else {
            $(window).trigger('about-close');
            TweenMax.to(self.$aboutOpenAnimate,0.55,{className:"-=active",ease:Strong.easeInOut,overwrite:true,force3D:true,onStart:function(){
                //TweenMax.to(self.$contactOpenUItargets,0.55,{className:"-=aboutOpen",ease:Strong.easeInOut,overwrite:false,force3D:true});
            }});
            self.isAboutOpen = false;
        }
        //$(this).removeClass('hover');
    });

};


UI.prototype.openContactPanel = function(){
    let self = this;
    self.$contactPanel.focus();
    TweenMax.fromTo(self.$contactPanel,0.75,{className:"-=active"},{className:"+=active",ease:Strong.easeInOut,overwrite:true,force3D:true,onComplete:function(){
        self.isContactOpen = true;
    }});
    //TweenMax.to(self.$contactOpenUItargets,0.55,{className:"+=contactOpen",ease:Strong.easeInOut,overwrite:false,force3D:true});
    TweenMax.to(self.$contactContent,0.55,{className:"+=active",ease:Strong.easeOut,overwrite:true,force3D:true,delay:0.35});
};


UI.prototype.closeContactPanel = function(){
    let self = this;
    self.isContactOpen = false;
    TweenMax.to(self.$contactPanel,0.45,{className:"-=active",ease:Strong.easeIn,overwrite:true,delay:0.15,force3D:true,onComplete:function(){
        //TweenMax.to(self.$contactOpenUItargets,0.55,{className:"-=contactOpen",ease:Strong.easeInOut,overwrite:false,force3D:true});
    }});
    TweenMax.to(self.$contactContent,0.55,{className:"-=active",ease:Strong.easeIn,overwrite:true,force3D:true});
    self.$contactPanel.blur();
};


UI.prototype.toggleFullScreen = function() {
    let self = this;
    if (!document.fullscreenElement &&    // alternative standard method
          !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        self.isFullscreen = true
    } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        self.isFullscreen = false
    }
    $(window).trigger('resize');
};

module.exports = UI;
