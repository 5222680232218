'use strict';

// IE listener fix
if (typeof(UserAgentInfo) != 'undefined' && !window.addEventListener){
    UserAgentInfo.strtBrowser = 1;
}
window.isIE = function() {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

window.$ = require('jquery');
window.jQuery = require('jquery');
import TweenMax from 'gsap/src/uncompressed/TweenMax';
window.isMobile = require('ismobilejs');
import * as THREE from 'three';
window.THREE = THREE;
//window.VirtualJoystick = require('../../bower_components/virtualjoystick.js/virtualjoystick');
//window.OrbitControls = require('three-orbit-controls')(THREE);
window.OBJLoader = require('three-obj-loader');
OBJLoader(THREE);
window.MTLLoader = require('three/examples/js/loaders/MTLLoader');
window.nipplejs = require('nipplejs');



$(() => {

    let $root = [];
    $root.window = window;
    // modules
    $root.window.UI = require('./modules/UI');
    $root.window.About = require('./modules/about');
    $root.window.CyberpunkRun = require('./cyberpunkRun/main');


    $(document).ready(function(){

        //-console.log(virtualjoystick)

        // init UI
        $root.ui = new UI($('#ui'));

        // init ABOUT
        $root.about = new About($('#aboutPanel'))

        // init game
        //$root.cyberpunkRun = new CyberpunkRun($('#mainScene'));


    });


    $(window).on('load',function(){



    });


    $(window).on('resize',function(){



    });

});
